.margin-x-1
    margin: 0 1rem

.margin-r-1
    margin-right: 1rem

.margin-r-2
    margin-right: 2rem

.margin-x-2
    margin: 0 2rem

.margin-x-3
    margin: 0 3rem

.margin-y-1
    margin: 1rem 0

.margin-y-2
    margin: 2rem 0

.margin-y-3
    margin: 3rem 0
    
@media (max-width: 768px) 
    .margin-x-1
        margin: 0 1rem

    .margin-x-2
        margin: 0 1rem

    .margin-x-3
        margin: 0 1rem

.cerebro-btn-blue
    background-color: #2c3f73
    padding: 10px 20px
    border-radius: 6px
    color: #ffff
    border-color: transparent
    &:hover
        color: #2c3f73
        background-color: #ffff
        border-color: #2c3f73
        transition: .3s
        cursor: pointer
        
.cerebro-btn-circle-blue
    background-color: #2c3f73
    padding: 10px 20px
    border-radius: 90%
    color: #ffff
    border-color: transparent
    padding: 6px 10px
    &:hover
        color: #2c3f73
        background-color: #ffff
        border-color: #2c3f73
        transition: .3s
        cursor: pointer


.btn-circle-icon
    border-radius: 90%
    padding: 0px
    display: flex
    justify-content: center
    align-items: center
    border-color: transparent
    background-color: transparent
    mat-icon
        padding: 0px
        font-size: 30px
        margin: 0px
    img
        border-radius: 50%

.bg-cerebro-grey
    color: #2b5070
    border-color: #e0dfe1 //#ffe401
    border: 1px solid #d1d1d1
    i
        padding: 4px 6px
    &:hover
        color: #e0dfe1
        background-color: #2b5070
        transition: .3s

.bg-cerebro-blue
    height: 40px
    color: #fff
    background-color: #2b5070!important
    border-color: #2b5070 //#ffe401
    border: 1px solid #d1d1d1
    mat-icon
        margin-right:5px
    &:hover
        color: #2b5070
        background-color: #ffff!important
        transition: .3s
    
@media screen and (max-width: 520px)
    .bg-cerebro-blue
        .description-btn
            display: none

.bg-cerebro-white
    color: #2b5070!important
    background-color: #ffff!important
    &:hover
        background-color: #fafafa!important
        border-color: #fafafa 
        border: 1px solid #d1d1d1
        transition: .3s

/*************** Scroll simple **********************/
.scroll-x
    overflow-x: hidden
    @media screen and (max-width: 720px)
        overflow-x: auto

.scroll--simple
  &::-webkit-scrollbar
    width: 6px
    height: 6px

  &::-webkit-scrollbar-track
    border-radius: 10px
    background: rgba(0, 0, 0, 0.1)

  &::-webkit-scrollbar-thumb
    border-radius: 10px
    background: rgba(0, 0, 0, 0.2)

    &:hover
      background: rgba(0, 0, 0, 0.4)

    &:active
      background: rgba(0, 0, 0, 0.9)

/********************** Dot active circle button *********/
.dot 
  height: 20px
  width: 20px
  background-color: #bbb
  border-radius: 50%
  display: inline-block

.dot-active
  background-color: #1e8e3d!important

/************************ Mat table ******************************/
.mat-row:hover 
  background-color: #9ab2ca
  transition: .3s
